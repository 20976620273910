import styled from "styled-components";
import Navbar from "../Navbar";
import LoginForm from "../forms/LoginForm";

const LoginPageHeader = () => {
  return (
    <Container>
      <Navbar />
      <LoginForm />
    </Container>
  );
};

const Container = styled.div`
  padding-left: 60px;
  padding-right: 60px;
  background-image: url(/assets/images/login_d_bg.png);
  min-height: 600px;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;

  @media screen and (max-width: 768px) {
    padding: 20px;
    background-color: #fff;
    background-image: none;
    min-height: fit-content;
    margin-bottom: 35px;
  }

  @media screen and (max-width: 480px) {
    padding: 20px 10px;
  }
`;

export default LoginPageHeader;
