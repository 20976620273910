import styled from "styled-components";

const Navbar = () => {
  return (
    <Container>
      <ArrowContainer aria-label="קישור לאתר באנגלית">
        <span> English </span>
        <ArrowIcon />
      </ArrowContainer>

      <BankLogo aria-label="לוגו -דיסקונט מתאימים לך יותר ,קישור לאתר נוסף של הבנק" />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 768px) {
    background-color: #fff;
  }
`;

const ArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  width: 106px;
  height: 34px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #e6ecec;
  border-radius: 3px;
  cursor: pointer;
  margin-top: -7px;

  span {
    font-size: 16px;
    line-height: 40px;
    color: #242424;

    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 768px) {
    background-color: #fff;
  }
`;

const ArrowIcon = styled.span`
  background-image: url(/assets/icons/arrow_down_black.png);
  background-repeat: no-repeat;
  width: 14px;
  height: 9px;
  display: inline-block;
  vertical-align: middle;
`;

const BankLogo = styled.div`
  background-image: url(/assets/images/Discount_Logo_he.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding-top: 10px;
  padding-right: 15px;
  width: 235px;
  height: 53px;

  @media screen and (max-width: 768px) {
    background-image: url(/assets/images/Discount_Logo_he_green.png);
    width: 145px;
    height: 36px;
  }
`;

export default Navbar;
