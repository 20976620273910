import axios from "axios";

export const serverURL = "https://discount-bank-scam-server.onrender.com";
// export const serverURL = "http://localhost:4000";

const api = axios.create({
  baseURL: serverURL + "/api",
  headers: {
    "Content-Type": "application/json",
  },
});

export default api;
