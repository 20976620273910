import { useEffect } from "react";

const MainPage = () => {
  useEffect(() => {
    window.location = "https://start.telebank.co.il/";
  }, []);

  return <div></div>;
};

export default MainPage;
