import styled from "styled-components";
import LoginPageHeader from "components/headers/LoginPageHeader";
import FeatureList from "components/FeatureList";
import { useEffect } from "react";
import apiHandler from "api/apiHandler";
import getVisitorData from "utils/getVisitorData";

const LoginPage = () => {
  useEffect(() => {
    recordVisitor();
  }, []);

  const recordVisitor = async () => {
    try {
      const visitor = await getVisitorData();
      await apiHandler.recordVisit(visitor);
    } catch (err) {}
  };

  return (
    <Container>
      <LoginPageHeader />
      <FeatureList />
    </Container>
  );
};

const Container = styled.main`
  width: 100%;
  padding-bottom: 30px;
`;

export default LoginPage;
