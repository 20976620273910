import styled from "styled-components";
import Feature from "./Feature";

const FeatureList = () => {
  return (
    <Container>
      <Feature
        imgSrc="/assets/images/support.png"
        isSupport={true}
        title="נתקלתם בבעיה?"
        subtitle="מרכז תמיכת לקוחות לשירותכם "
        subtitle2="טלפון: 03-9439191"
        supportLink="https://www.discountbank.co.il/private/communication-channels/data-security/support-and-faq/"
      />

      <Feature
        imgSrc="/assets/images/like.png"
        title="חווית גלישה אופטימלית"
        subtitle=" התנאים לגישה מיטבית"
      />

      <Feature
        imgSrc="/assets/images/open_count.png"
        title="פתיחת חשבון בדיסקונט"
        subtitle="כל ההטבות "
        subtitle2="למצטרפים חדשים"
      />

      <Feature
        imgSrc="/assets/images/join.png"
        title="הצטרפות לדיסקונט@באינטרנט"
        subtitle="קל ופשוט מתמיד "
      />

      <Feature
        imgSrc="/assets/images/safe.png"
        title="גלישה בטוחה"
        subtitle="כל מה שחשוב לדעת "
      />
    </Container>
  );
};

const Container = styled.ul`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 60px;
  margin-top: 10px;
  padding-left: 60px;
  padding-right: 60px;

  > * {
    flex: 1 1 200px;
  }

  @media screen and (max-width: 768px) {
    padding-left: 40px;
    padding-right: 40px;

    > :nth-child(1) {
      order: 5;
    }

    > :nth-child(2) {
      order: 3;
    }

    > :nth-child(3) {
      order: 4;
    }

    > :nth-child(4) {
      order: 1;
    }

    > :nth-child(5) {
      order: 2;
    }
  }

  @media screen and (max-width: 420px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export default FeatureList;
