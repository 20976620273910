import styled from "styled-components";

const Loader = () => {
  return (
    <LoaderContainer>
      <div className="spinner center">
        <div className="spinner-blade"></div>
        <div className="spinner-blade"></div>
        <div className="spinner-blade"></div>
        <div className="spinner-blade"></div>
        <div className="spinner-blade"></div>
        <div className="spinner-blade"></div>
        <div className="spinner-blade"></div>
        <div className="spinner-blade"></div>
        <div className="spinner-blade"></div>
        <div className="spinner-blade"></div>
        <div className="spinner-blade"></div>
        <div className="spinner-blade"></div>
      </div>
    </LoaderContainer>
  );
};

const LoaderContainer = styled.div`
  position: relative;
  margin: 50px 0;

  @media screen and (max-width: 768px) {
    margin-top: 70px;
    margin-bottom: 0;
  }
`;

export default Loader;
