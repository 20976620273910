import styled from "styled-components";

const Feature = ({
  isSupport = false,
  imgSrc,
  title,
  subtitle,
  subtitle2,
  link,
  supportLink,
}) => {
  const Container = isSupport
    ? SupportContainer
    : (props) => (
        <FeatureContainer href={link || "#"} target="_blank" {...props} />
      );

  return (
    <Container>
      <FeatureImage img={imgSrc} />
      <FeatureTitle>{title}</FeatureTitle>

      <FeatureSubtitlesContainer>
        <FeatureSubtitle>{subtitle}</FeatureSubtitle>
        {!!subtitle2 && <FeatureSubtitle>{subtitle2}</FeatureSubtitle>}
      </FeatureSubtitlesContainer>

      {isSupport && (
        <SupportLink href={supportLink} target="_blank">
          <ArrowLeftImage src="/assets/icons/arrow_right_gray.png" />
          <span>שעות פעילות</span>
        </SupportLink>
      )}
    </Container>
  );
};

const FeatureContainer = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
  min-height: fit-content;
  margin-bottom: auto;
  padding-bottom: 0;
  padding-top: 5px;
  margin-top: 0;
`;

const SupportContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
  min-height: fit-content;
  margin-bottom: auto;
  padding-bottom: 0;
  padding-top: 5px;
  margin-top: 0;
  background-color: #f6fcf0;
`;

const FeatureImage = styled.div`
  width: 50px;
  height: 50px;
  margin: 10px auto;
  background-image: url(${({ img }) => img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

const FeatureTitle = styled.h4`
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  padding: 10px 0;
  font-weight: 700;
  color: #2d3948;
`;

const FeatureSubtitlesContainer = styled.div``;

const FeatureSubtitle = styled.p`
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
  color: #606060;
  text-align: center;
`;

const SupportLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding-top: 2px;
  color: #606060;
  padding-top: 6px;
  padding-left: 15px;

  @media screen and (max-width: 768px) {
    align-self: flex-end;
    margin-right: 10%;
  }
`;

const ArrowLeftImage = styled.img`
  margin-right: 5px;
`;

export default Feature;
