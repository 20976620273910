import styled from "styled-components";
import { useState } from "react";
import { LuEye, LuEyeOff } from "react-icons/lu";

const Input = ({
  id,
  title,
  type = "text",
  maxLength = 0,
  isNumeric = false,
  value,
  placeholder,
  onChange,
  ...props
}) => {
  const [hiddenPassword, setHiddenPassword] = useState(true);

  const handleChange = (e) => {
    const { value: currentValue } = e.currentTarget;
    const newValue =
      ["number", "tel"].includes(type) || isNumeric
        ? currentValue.trim()
        : currentValue;

    if (newValue === "") {
      onChange(newValue);
      return;
    }

    if (maxLength && newValue.length > maxLength) {
      return;
    } else if (isNumeric && !isNumericString(newValue)) {
      return;
    }

    onChange(newValue);
  };

  const isNumericString = (str) => {
    // Trim the string to remove any leading or trailing whitespace
    str = str.trim();

    // Check if the string is empty or not a number
    if (str === "" || isNaN(str)) {
      return false;
    }

    return true;
  };

  const hidePassword = () => {
    setHiddenPassword(true);
  };

  const showPassword = () => {
    setHiddenPassword(false);
  };

  const getInputType = () => {
    return type === "password"
      ? hiddenPassword
        ? "password"
        : "text"
      : "text";
  };

  return (
    <Container>
      <InputLabel htmlFor={id}>{title}</InputLabel>
      <InputBoxContainer>
        {type === "password" && value ? (
          hiddenPassword ? (
            <OpenEye onClick={showPassword} />
          ) : (
            <ClosedEye onClick={hidePassword} />
          )
        ) : null}
        <InputBox
          id={id}
          type={getInputType()}
          value={value}
          onChange={handleChange}
          {...props}
          placeholder=""
        />
      </InputBoxContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const InputLabel = styled.label`
  display: block;
  color: #505050;
  font-size: 16px;
  font-weight: 400;
  line-height: 14px;
  direction: rtl;
  padding-top: 8px;
  padding-bottom: 8px;
`;

const InputBoxContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const OpenEye = styled(LuEye)`
  position: absolute;
  left: 10px;
  cursor: pointer;
  font-size: 24px;
`;

const ClosedEye = styled(LuEyeOff)`
  position: absolute;
  left: 10px;
  cursor: pointer;
  font-size: 24px;
`;

const InputBox = styled.input`
  flex: 1;
  height: 40px;
  width: 100%;
  border: 1px solid #242424;
  border-radius: 5px;
  outline: none;
  padding-left: 0;
  padding-right: 3px;
  text-align: right;
  background-color: #fff;
  font-size: 14px;
  color: #2d3948;
`;

export default Input;
