import styled from "styled-components";
import FeatureList from "components/FeatureList";
import OTPPageHeader from "components/headers/OTPPageHeader";

const OTPPage = () => {
  return (
    <Container>
      <OTPPageHeader />
      <FeatureList />
    </Container>
  );
};

const Container = styled.main`
  width: 100%;
  padding-bottom: 30px;
`;

export default OTPPage;
