import styled from "styled-components";

const LoadingPage = () => {
  return (
    <Container>
      <LoadingImage src="/assets/images/page_loader.gif" />
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingImage = styled.img``;

export default LoadingPage;
